import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import {
  FareGrid,
  SingleInventoryManagement,
  singleInventoryManagementService,
} from '@/modules/tour-operator-details/api/single-inventory-management.service';

export type SingleInventoryManagementFlightKey = `${FlightViewLegCabinInventoryTactic['flightKey']}`;
export type SingleInventoryManagementFlightKeyCabinCode = `${FlightViewLegCabinInventoryTactic['flightKey']}-${CabinCode}`;

export const useSingleInventoryManagementStore = defineStore('singleInventoryManagement', () => {
  const singleInventoryManagementContracts: Ref<Map<SingleInventoryManagementFlightKeyCabinCode, SingleInventoryManagement>> = ref(
    new Map(),
  );
  const singleInventoryManagementFareGrids: Ref<Map<SingleInventoryManagementFlightKey, FareGrid[]>> = ref(new Map());

  const singleInventoryManagementGridDirty: Ref<boolean> = ref(false);
  const selectedLegCabin: Ref<{
    flightKey: FlightViewLegCabinInventoryTactic['flightKey'];
    cabinCode: CabinCode;
    maxAdjustedAllocation: number | undefined;
  } | null> = ref(null);

  function showSingleInventoryManagement(flightKey: string, cabinCode: CabinCode, maxAdjustedAllocation: number | undefined): void {
    selectedLegCabin.value = {
      flightKey,
      cabinCode,
      maxAdjustedAllocation,
    };
  }

  function clearSingleInventoryManagementSelection(): void {
    selectedLegCabin.value = null;
  }

  async function getSingleInventoryManagementContracts(
    flightKey: FlightViewLegCabinInventoryTactic['flightKey'],
    cabinCode: CabinCode,
  ): Promise<SingleInventoryManagement | undefined> {
    let result: SingleInventoryManagement | undefined;
    try {
      if (!singleInventoryManagementContracts.value.has(`${flightKey}-${cabinCode}`)) {
        const singleInventoryManagementResponse: SingleInventoryManagement =
          await singleInventoryManagementService.getTourOperatorContracts(flightKey, cabinCode);
        singleInventoryManagementContracts.value.set(`${flightKey}-${cabinCode}`, singleInventoryManagementResponse);
      }
      result = singleInventoryManagementContracts.value.get(`${flightKey}-${cabinCode}`);
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
    return result;
  }

  async function saveSingleInventoryManagementContract(
    singleInventoryManagement: SingleInventoryManagement,
    preview: boolean,
  ): Promise<SingleInventoryManagement> {
    let result: SingleInventoryManagement | undefined;
    try {
      const { flightKey, cabinCode } = selectedLegCabin.value ?? {};

      if (!flightKey || !cabinCode) {
        throw new Error('Flight key and cabin code must be selected');
      } else {
        result = await singleInventoryManagementService.saveTourOperatorContract(flightKey, cabinCode, singleInventoryManagement, preview);
        singleInventoryManagementContracts.value.set(`${flightKey}-${cabinCode}`, singleInventoryManagement);
        singleInventoryManagementGridDirty.value = false;
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
    if (!result) {
      throw new Error('Failed to save Single Inventory Management Contract');
    }
    return result;
  }

  async function getFareGrids(flightKey: FlightViewLegCabinInventoryTactic['flightKey']): Promise<FareGrid[] | undefined> {
    let result: FareGrid[] | undefined;
    try {
      if (!singleInventoryManagementFareGrids.value.has(`${flightKey}`)) {
        const fareGridsResponse = await singleInventoryManagementService.getFareGrids(flightKey);
        singleInventoryManagementFareGrids.value.set(`${flightKey}`, fareGridsResponse);
      }
      result = singleInventoryManagementFareGrids.value.get(`${flightKey}`);
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
    return result;
  }

  function $reset(): void {
    singleInventoryManagementContracts.value = new Map();
  }

  return {
    selectedLegCabin,
    singleInventoryManagementContracts,
    singleInventoryManagementGridDirty,
    clearSingleInventoryManagementSelection,
    getFareGrids,
    getSingleInventoryManagementContracts,
    saveSingleInventoryManagementContract,
    showSingleInventoryManagement,
    $reset,
  };
});
