export const en = {
  tour_operator_details: {
    titles: {
      tour_operator_details: 'Tour Operator Details',
    },
    table_columns: {
      tour_operator_name: 'Tour Operator Name',
      cabin_code: 'Cabin',
      contract_type: 'Contract Type',
      contract_code: 'Contract Code',
      initial: 'Initial',
      confirmed: 'Confirmed',
      sold: 'Sold',
      available: 'Available',
      initial_utilization: 'Initial Utilization',
      confirmed_utilization: 'Confirmed Utilization',
      initial_rate: 'Initial Rate',
      confirmed_rate: 'Confirmed Rate',
      sold_revenue: 'Sold Revenue',
      sold_average_rate: 'Sold Average Rate',
      total_avg: 'Total/Avg',
    },
  },
  tour_operator_pool: {
    buttons: {
      revert_changes: 'Revert Changes',
      save: 'Save',
    },
    dialogs: {
      reset_data: {
        title: 'Revert Changes',
        message:
          'All your changes will be lost and the data will be reset to the previously saved state. Are you sure you want to continue?',
        confirm_button: 'Revert Changes',
        cancel_button: 'Cancel',
      },
      save_data: {
        title: 'Save Changes',
        message: 'All your changes will be saved an applied to the selected flight. Are you sure you want to continue?',
        confirm_button: 'Save',
        cancel_button: 'Cancel',
      },
      save_success: {
        message: 'Changes saved successfully',
      },
    },
    table_column_header: {
      contract: 'Contract',
      fare: 'Fare',
      alloc: 'Alloc',
      adj_alloc: 'AdjAlloc',
      sold: 'Sold',
      sa: 'SA',
      active: 'Active',
      calcClass: 'CalcClass',
      fareGrid: 'FareGrid',
      srcLAF: 'srcLAF',
      ceiling: 'Ceiling',
      offset: 'Offset',
    },
    table_column_header_description: {
      contract: 'Tour Operator Code',
      fare: 'Fare',
      alloc: 'Allocation',
      adj_alloc: 'Adjusted Allocation',
      sold: 'Sold',
      sa: 'Seat Availability',
      active: 'Tour Operator Active',
      calcClass: 'Calculated Class',
      fareGrid: 'Fare Grid',
      srcLAF: 'source LAF',
      ceiling: 'Ceiling',
      offset: 'Offset',
    },
    table_column_content: {
      class: 'Class',
      fare_value: 'Fare Value',
      dynamic: 'Dynamic',
      dynamic_short: 'DNC',
    },
  },
};
